@font-face {
  font-family: 'NTBau';
  src: url('./assets/fonts/NTBau-Regular.otf') format('opentype');
  font-size: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NTBau';
  src: url('./assets/fonts/NTBau-Bold.otf') format('opentype');
  font-size: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'FavoritMono';
  src: url('./assets/fonts/FavoritMono-Regular.otf') format('opentype');
  font-size: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FavoritMono';
  src: url('./assets/fonts/FavoritMono-Bold.otf') format('opentype');
  font-size: normal;
  font-weight: bold;
  font-display: swap;
}

.loader {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
